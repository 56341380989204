/* Estilos Globais*/
.servicos-page {
  font-family: 'Inter', sans-serif;
  color: #2c3e50;
  line-height: 1.6;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.section {
  padding: 80px 0;
}

.text-primary {
  color: #EB5F2C;
}

.bg-primary {
  background-color: #EB5F2C;
}

.bg-secondary {
  background-color: #6c757d;
}

.bg-dark {
  background-color: #212529;
  color: white;
}

.bg-light {
  background-color: #f8f9fa;
}

.btn {
  display: inline-block;
  padding: 12px 24px;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
}

.btn-primary {
  background-color: #EB5F2C;
  color: white;
  border: none;
}

.btn-primary:hover {
  background-color: #D84315;
  transform: translateY(-2px);
}

.btn-outline-primary {
  background-color: transparent;
  color: #EB5F2C;
  border: 2px solid #EB5F2C;
}

.btn-outline-primary:hover {
  background-color: #EB5F2C;
  color: white;
}

.btn-lg {
  padding: 15px 30px;
  font-size: 1.1rem;
}

.section-header {
  text-align: center;
  margin-bottom: 60px;
}

.section-header h2 {
  font-size: 2.5rem;
  margin-bottom: 15px;
}

.section-header p {
  font-size: 1.2rem;
  max-width: 700px;
  margin: 0 auto;
}

/*Hero Section */
.hero-section {
  position: relative;
  height: 100vh;
  min-height: 600px;
  overflow: hidden;
}

.hero-slides {
  position: relative;
  height: 100%;
  width: 100%;
}

.hero-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
  transition: opacity 1s ease;
}

.hero-slide.active {
  opacity: 1;
}

.slide-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.slide-content {
  position: relative;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  padding: 0 20px;
  max-width: 800px;
  margin: 0 auto;
}

.slide-content h1 {
  font-size: 3rem;
  margin-bottom: 20px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.slide-content p {
  font-size: 1.5rem;
  margin-bottom: 30px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.2);
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  cursor: pointer;
  transition: all 0.3s ease;
}

.carousel-control:hover {
  background: rgba(255, 255, 255, 0.4);
}

.carousel-control.prev {
  left: 20px;
}

.carousel-control.next {
  right: 20px;
}

.carousel-indicators {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  gap: 10px;
  z-index: 10;
}

.carousel-indicators button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: none;
  background: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  padding: 0;
  transition: all 0.3s ease;
}

.carousel-indicators button.active {
  background: white;
  transform: scale(1.2);
}

/*Especialidades Section */
.especialidades-section {
  background: linear-gradient(135deg, #FFF8F6 0%, #FFFFFF 100%);
  padding: 6rem 0;
  position: relative;
}

.especialidades-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
  position: relative;
  z-index: 1;
}

.especialidade-card {
  background: white;
  border-radius: 24px;
  padding: 2.5rem;
  box-shadow: 0 15px 40px -15px rgba(235,95,44,0.15);
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  border: 1px solid rgba(235,95,44,0.05);
  position: relative;
  overflow: hidden;
}

.especialidade-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, #EB5F2C 0%, #FF8A5B 100%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.especialidade-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 25px 60px -15px rgba(235,95,44,0.25);
}

.especialidade-card:hover::before {
  opacity: 1;
}

.card-icon {
  width: 72px;
  height: 72px;
  background: linear-gradient(135deg, rgba(235,95,44,0.1) 0%, rgba(255,138,91,0.1) 100%);
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  color: #EB5F2C;
  font-size: 1.75rem;
}

.card-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #2c3e50;
}

.card-desc {
  color: #6a6a6a;
  line-height: 1.7;
  margin-bottom: 1.5rem;
}

.feature-list {
  margin: 2rem 0;
}

.feature-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
  padding-left: 1.5rem;
  position: relative;
}

.feature-item::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0.5rem;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #EB5F2C;
}

.metrics {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 1.5rem 0;
}

.metric-badge {
  background: rgba(235, 95, 44, 0.1);
  color: #EB5F2C;
  padding: 4px 10px;
  border-radius: 20px;
  font-size: 0.85rem;
  font-weight: 500;
}

/*Soluções Section */
.solucoes-section {
  padding: 6rem 0;
  background: #FFFFFF;
}

.solucoes-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 1.5rem;
}

.solucao-card {
  background: white;
  border-radius: 16px;
  padding: 1rem;
  transition: all 0.3s ease;
  border: 1px solid #f0f0f0;
  text-align: center;
  position: relative;
}

.solucao-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 20px 40px -15px rgba(235,95,44,0.15);
  border-color: rgba(235,95,44,0.2);
}

.solucao-icon {
  width: 60px;
  height: 60px;
  margin: 0 auto 1.5rem;
  background: linear-gradient(135deg, rgba(235,95,44,0.1) 0%, rgba(255,138,91,0.1) 100%);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #EB5F2C;
  font-size: 1.5rem;
}

.solucao-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #EB5F2C;
}

.solucao-list {
  text-align: left;
  margin-top: 1.5rem;
}

.solucao-list li {
  margin-bottom: 0.75rem;
  position: relative;
  color: #6a6a6a;
}

.solucao-list li::before {
  position: absolute;
  color: #EB5F2C;
}

/*Processo Section */
.processo-section {
  padding: 80px 0;
}

.processo-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
}

.processo-steps {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 40px;
}

.processo-steps:before {
  content: '';
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  height: 2px;
  background: linear-gradient(90deg, rgba(235,95,44,0.1) 0%, rgba(235,95,44,0.3) 50%, rgba(235,95,44,0.1) 100%);
}

.processo-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  width: 22%;
  cursor: pointer;
}

.step-icon-container {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  border: 2px solid rgba(235,95,44,0.2);
  margin-bottom: 20px;
  transition: all 0.3s ease;
}

.processo-step.active .step-icon-container {
  background: #EB5F2C;
  border-color: #EB5F2C;
  transform: scale(1.1);
}

.processo-step.active .step-icon {
  color: white;
}

.step-icon {
  color: #EB5F2C;
  font-size: 30px;
  transition: all 0.3s ease;
}

.step-title {
  font-size: 1.2rem;
  color: #EB5F2C;
  margin-bottom: 15px;
  text-align: center;
  font-weight: 600;
}

.processo-step.active .step-title {
  color: #D84315;
}

.step-content {
  background: white;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);
  margin-top: 20px;
}

.step-details {
  list-style: none;
  padding: 0;
  margin: 0;
}

.step-details li {
  padding: 8px 0;
  position: relative;
  padding-left: 25px;
  color: #555;
}

.step-details li:before {
  content: '';
  position: absolute;
  left: 0;
  top: 15px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #EB5F2C;
}

/*Cases Section */
.cases-section {
  padding: 80px 0;
}

.cases-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 30px;
  margin: 0 20px;
}

.case-card {
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.case-card:hover {
  transform: translateY(-10px);
}

.case-image {
  position: relative;
  height: 250px;
  overflow: hidden;
}

.case-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.case-card:hover .case-image img {
  transform: scale(1.05);
}

.case-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
  color: white;
}

.case-overlay h3 {
  margin: 0;
  font-size: 1.5rem;
}

.case-category {
  display: inline-block;
  background: #EB5F2C;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.9rem;
  margin-top: 10px;
}

.case-content {
  padding: 25px;
}

.case-content p {
  color: #555;
  margin-bottom: 15px;
}

.case-stats {
  margin: 20px 0;
  display: flex;
}

.stat-item {
  margin-right: 30px;
}

.stat-value {
  display: block;
  font-size: 2rem;
  font-weight: bold;
  color: #EB5F2C;
}

.stat-label {
  font-size: 0.9rem;
  color: #6c757d;
}

.btn-link {
  color: #EB5F2C;
  text-decoration: none;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  transition: color 0.3s ease;
}

.btn-link:hover {
  color: #D84315;
  text-decoration: underline;
}

.btn-link svg {
  margin-left: 8px;
  transition: transform 0.3s ease;
}

.btn-link:hover svg {
  transform: translateX(5px);
}


/*Depoimentos Section */
.depoimentos-section {
  padding: 80px 0;
}

.depoimentos-slider {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin: 0 20px;
}

.depoimento-card {
  background: rgba(255, 255, 255, 0.1);
  padding: 30px;
  border-radius: 12px;
  transition: transform 0.3s ease;
}

.depoimento-card:hover {
  transform: translateY(-10px);
  background: rgba(255, 255, 255, 0.15);
}

.depoimento-rating {
  color: #FFD700;
  margin-bottom: 20px;
}

.depoimento-rating svg {
  margin-right: 5px;
}

blockquote p {
  font-size: 1.1rem;
  font-style: italic;
  margin-bottom: 25px;
  position: relative;
  color: #f8f9fa;
}

blockquote p:before,
blockquote p:after {
  content: '"';
  font-size: 2rem;
  color: rgba(255, 255, 255, 0.3);
  position: absolute;
}

blockquote p:before {
  top: -15px;
  left: -15px;
}

blockquote p:after {
  bottom: -25px;
  right: -15px;
}

.depoimento-author {
  display: flex;
  align-items: center;
}

.depoimento-author img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
  border: 2px solid rgba(255, 255, 255, 0.2);
}

.depoimento-author div {
  display: flex;
  flex-direction: column;
}

.depoimento-author strong {
  font-size: 1.1rem;
  color: white;
}

.depoimento-author span {
  font-size: 0.9rem;
  opacity: 0.8;
  color: #f8f9fa;
}


/*CTA Section*/
.cta-section {
  padding: 100px 0;
  text-align: center;
  background: linear-gradient(135deg, #cde6f098 0%, #e6dcff50 50%);
  color: rgb(32, 32, 32);
}

.cta-content h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.cta-content .lead {
  font-size: 1.3rem;
  max-width: 700px;
  margin: 0 auto 40px;
  opacity: 0.9;
}

.cta-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}


/*Responsividade*/
@media (max-width: 992px) {
  .especialidades-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .section-header h2 {
    font-size: 2.2rem;
  }
  
  .especialidades-grid,
  .solucoes-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
  
  .especialidade-card {
    padding: 2rem;
  }

  .cases-grid {
    grid-template-columns: 1fr;
  }
  
  .cta-buttons {
    flex-direction: column;
    align-items: center;
  }
  
  .hero-section {
    min-height: 500px;
  }
  
  .slide-content h1 {
    font-size: 2rem;
  }
  
  .slide-content p {
    font-size: 1.1rem;
  }

  .section {
    padding: 50px 0;
  }
}

@media (max-width: 576px) {
  .especialidades-grid {
    grid-template-columns: 1fr;
  }
  
  .section-header h2 {
    font-size: 1.8rem;
  }
  
  .cta-content h2 {
    font-size: 2rem;
  }
  
  .cta-content .lead {
    font-size: 1.1rem;
  }

  .section {
    padding: 40px 0;
  }
}


